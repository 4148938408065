//const token =  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiTHVjYXMgUHJvZHVjYW8iLCJtYWlsIjoicHJvZHVjYW9AZ21haWwuY29tIiwiaWF0IjoxNzI2Njk1Mzc4LCJleHAiOjE3MjkyODczNzgsInN1YiI6IjY2ZGYwNTE5NDYxYjBkMzRmMTk3ZDFjNSJ9.-MqqlBhD0yFDtFTW2kvPr9Ss2oUHL6gE3lKhFGzMeTI"// localStorage.getItem("authToken");

import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TextField,
  Button,
} from "@mui/material";
import axios from "./axiosConfig"; // Importando a configuração do Axios

// Definindo a interface dos dados do usuário e suas configurações
interface UserConfig {
  id: string;
  exchange: number;
  apiKey: string;
  secretKey: string;
  apiUrl: string;
  profit: number;
  buyQty: number;
  stopLess: number;
  interval: string;
  period: string;
  telegramBotToken: string;
  telegramChatId: string;
  userId: string; // Adicionado aqui o userId na interface
}

interface User {
  id: string;
  username: string;
  name: string;
  mail: string;
  userConfigs: UserConfig[];
}

const UserDetails: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false); // Estado para controle da edição

  useEffect(() => {
    const fetchUserData = async () => {
      try {
      //  const token = localStorage.getItem("authToken");
      const token =  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiTHVjYXMgUHJvZHVjYW8iLCJtYWlsIjoicHJvZHVjYW9AZ21haWwuY29tIiwiaWF0IjoxNzI2Njk1Mzc4LCJleHAiOjE3MjkyODczNzgsInN1YiI6IjY2ZGYwNTE5NDYxYjBkMzRmMTk3ZDFjNSJ9.-MqqlBhD0yFDtFTW2kvPr9Ss2oUHL6gE3lKhFGzMeTI"// localStorage.getItem("authToken");

        if (!token) {
          setError("Usuário não autenticado.");
          setLoading(false);
          return;
        }

        const response = await axios.get("/me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUser(response.data);
        setLoading(false);
      } catch (error: any) {
        console.error("Failed to fetch user data:", error);
        setError("Falha ao carregar os dados do usuário.");
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: keyof User) => {
    if (user) {
      setUser({ ...user, [key]: e.target.value });
    }
  };
  
  const handleConfigChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string, key: keyof UserConfig) => {
    if (user) {
      const updatedConfigs = user.userConfigs.map((config) =>
        config.id === id ? { ...config, [key]: e.target.value } : config
      );
      setUser({ ...user, userConfigs: updatedConfigs });
    }
  };
  

  const handleSave = async () => {
    try {
    //  const token = localStorage.getItem("authToken");
    const token =  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiTHVjYXMgUHJvZHVjYW8iLCJtYWlsIjoicHJvZHVjYW9AZ21haWwuY29tIiwiaWF0IjoxNzI2Njk1Mzc4LCJleHAiOjE3MjkyODczNzgsInN1YiI6IjY2ZGYwNTE5NDYxYjBkMzRmMTk3ZDFjNSJ9.-MqqlBhD0yFDtFTW2kvPr9Ss2oUHL6gE3lKhFGzMeTI"// localStorage.getItem("authToken");

      if (!token) {
        setError("Usuário não autenticado.");
        return;
      }

      // Incluindo o userId manualmente na requisição caso seja necessário
      const updatedUser = { ...user, userId: user?.id };

      await axios.put(`/users/${user?.id}`, updatedUser, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setIsEditing(false);
    } catch (error: any) {
      console.error("Failed to save user data:", error);
      setError("Falha ao salvar os dados do usuário.");
    }
  };

  if (loading) {
    return (
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container component="main" maxWidth="md">
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="md">
      <Typography component="h1" variant="h5" sx={{ mb: 4 }}>
        Detalhes do Usuário
      </Typography>
      {user && (
        <>
          <Typography variant="h6">Informações Básicas:</Typography>
          <TextField
            label="Nome"
            value={user.name}
            onChange={(e) => handleInputChange(e, "name")}
            fullWidth
            disabled={!isEditing}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Username"
            value={user.username}
            onChange={(e) => handleInputChange(e, "username")}
            fullWidth
            disabled={!isEditing}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Email"
            value={user.mail}
            onChange={(e) => handleInputChange(e, "mail")}
            fullWidth
            disabled={!isEditing}
            sx={{ mb: 2 }}
          />

          <Typography variant="h6" sx={{ mt: 4 }}>
            Configurações do Usuário:
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Exchange</TableCell>
                  <TableCell>API Key</TableCell>
                  <TableCell>Profit</TableCell>
                  <TableCell>Quantidade de Compra</TableCell>
                  <TableCell>Stop Loss</TableCell>
                  <TableCell>Intervalo</TableCell>
                  <TableCell>Período</TableCell>
                  <TableCell>Telegram Bot Token</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user.userConfigs.map((config) => (
                  <TableRow key={config.id}>
                    <TableCell>
                      <TextField
                        value={config.exchange}
                        onChange={(e) => handleConfigChange(e, config.id, "exchange")}
                        fullWidth
                        disabled={!isEditing}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={config.apiKey}
                        onChange={(e) => handleConfigChange(e, config.id, "apiKey")}
                        fullWidth
                        disabled={!isEditing}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={config.profit}
                        onChange={(e) => handleConfigChange(e, config.id, "profit")}
                        fullWidth
                        disabled={!isEditing}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={config.buyQty}
                        onChange={(e) => handleConfigChange(e, config.id, "buyQty")}
                        fullWidth
                        disabled={!isEditing}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={config.stopLess}
                        onChange={(e) => handleConfigChange(e, config.id, "stopLess")}
                        fullWidth
                        disabled={!isEditing}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={config.interval}
                        onChange={(e) => handleConfigChange(e, config.id, "interval")}
                        fullWidth
                        disabled={!isEditing}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={config.period}
                        onChange={(e) => handleConfigChange(e, config.id, "period")}
                        fullWidth
                        disabled={!isEditing}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={config.telegramBotToken}
                        onChange={(e) => handleConfigChange(e, config.id, "telegramBotToken")}
                        fullWidth
                        disabled={!isEditing}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {isEditing ? (
            <Button variant="contained" onClick={handleSave} sx={{ mt: 3 }}>
              Salvar
            </Button>
          ) : (
            <Button variant="contained" onClick={() => setIsEditing(true)} sx={{ mt: 3 }}>
              Editar
            </Button>
          )}
        </>
      )}
    </Container>
  );
};

export default UserDetails;
