// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import Dashboard from "./Dashboard";
import UserList from "./ListUsers";
import SolicList from "./ListSolic";
import Me from "./Me";
import EditUser from "./EditUser";
import PosicaoAll from "./PosicaoAll";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/listusers" element={<UserList />} />
        <Route path="/listsolic" element={<SolicList />} />
        <Route path="/me" element={<Me />} />
        <Route path="/edituser/" element={<EditUser />} />
        <Route path="/posicaoall/" element={<PosicaoAll />} />
      </Routes>
    </Router>
  );
}

export default App;