import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import axios from "./axiosConfig"; // Configuração de Axios que já foi feita

interface Solic {
  id: string;
  symbol: string;
  status: string;
  created_at: string;
  userId: string;
  forceBuy: boolean;
}

const SolicList: React.FC = () => {
  const [solics, setSolics] = useState<Solic[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSolics = async () => {
      try {
        const response = await axios.get("/solic"); // Faz a requisição para buscar as solicitações
        setSolics(response.data);
        setLoading(false);
      } catch (error: any) {
        console.error("Failed to fetch solics:", error);
        setError("Falha ao carregar a lista de solicitações. Tente novamente.");
        setLoading(false);
      }
    };

    fetchSolics();
  }, []);

  if (loading) {
    return (
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container component="main" maxWidth="md">
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="md">
      <Typography component="h1" variant="h5" sx={{ mb: 4 }}>
        Lista de Solicitações (Solic)
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Symbol</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Data de Criação</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>Force Buy</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {solics.map((solic) => (
              <TableRow key={solic.id}>
                <TableCell>{solic.id}</TableCell>
                <TableCell>{solic.symbol}</TableCell>
                <TableCell>{solic.status}</TableCell>
                <TableCell>{new Date(solic.created_at).toLocaleDateString()}</TableCell>
                <TableCell>{solic.userId}</TableCell>
                <TableCell>{solic.forceBuy ? "Sim" : "Não"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default SolicList;
