import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import axios from "./axiosConfig"; // Importando a configuração do Axios

// Definindo a interface dos dados do usuário e suas configurações
interface UserConfig {
  id: string;
  exchange: number;
  apiKey: string;
  secretKey: string;
  apiUrl: string;
  profit: number;
  buyQty: number;
  stopLess: number;
  interval: string;
  period: string;
  telegramBotToken: string;
  telegramChatId: string;
  userId: string;
}

interface User {
  id: string;
  username: string;
  name: string;
  mail: string;
  userConfigs: UserConfig[];
}

const UserDetails: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Pegando o token do localStorage (ou sessionStorage)
        const token =  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiTHVjYXMgUHJvZHVjYW8iLCJtYWlsIjoicHJvZHVjYW9AZ21haWwuY29tIiwiaWF0IjoxNzI2Njk1Mzc4LCJleHAiOjE3MjkyODczNzgsInN1YiI6IjY2ZGYwNTE5NDYxYjBkMzRmMTk3ZDFjNSJ9.-MqqlBhD0yFDtFTW2kvPr9Ss2oUHL6gE3lKhFGzMeTI"// localStorage.getItem("authToken");

        // Se não houver token, pode tratar como erro ou redirecionar para login
        if (!token) {
          setError("Usuário não autenticado.");
          setLoading(false);
          return;
        }

        // Fazendo a requisição GET com o token no cabeçalho Authorization
        const response = await axios.get("/me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        setUser(response.data);
        setLoading(false);
      } catch (error: any) {
        console.error("Failed to fetch user data:", error);
        setError("Falha ao carregar os dados do usuário.");
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return (
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container component="main" maxWidth="md">
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="md">
      <Typography component="h1" variant="h5" sx={{ mb: 4 }}>
        Detalhes do Usuário
      </Typography>
      {user && (
        <>
          <Typography variant="h6">Informações Básicas:</Typography>
          <Typography>Nome: {user.name}</Typography>
          <Typography>Username: {user.username}</Typography>
          <Typography>Email: {user.mail}</Typography>

          <Typography variant="h6" sx={{ mt: 4 }}>
            Configurações do Usuário:
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID Configuração</TableCell>
                  <TableCell>Exchange</TableCell>
                  <TableCell>API Key</TableCell>
                  <TableCell>Secret Key</TableCell>
                  <TableCell>Profit</TableCell>
                  <TableCell>Quantidade de Compra</TableCell>
                  <TableCell>Stop Loss</TableCell>
                  <TableCell>Intervalo</TableCell>
                  <TableCell>Período</TableCell>
                  <TableCell>Telegram Bot Token</TableCell>
                  <TableCell>Telegram Chat ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user.userConfigs.map((config) => (
                  <TableRow key={config.id}>
                    <TableCell>{config.id}</TableCell>
                    <TableCell>{config.exchange}</TableCell>
                    <TableCell>{config.apiKey}</TableCell>
                    <TableCell>{config.secretKey}</TableCell>
                    <TableCell>{config.profit}</TableCell>
                    <TableCell>{config.buyQty}</TableCell>
                    <TableCell>{config.stopLess}</TableCell>
                    <TableCell>{config.interval}</TableCell>
                    <TableCell>{config.period}</TableCell>
                    <TableCell>{config.telegramBotToken}</TableCell>
                    <TableCell>{config.telegramChatId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Container>
  );
};

export default UserDetails;
