// src/axiosConfig.ts
import axios from 'axios';

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: 'https://apibinance.store', // Colocar url da api no env depois
 // baseURL: 'http://127.0.0.1:4001', // Colocar url da api no env depois
  timeout: 10000, // Ver quanto de timeout deixar
  headers: {
    'Content-Type': 'application/json',
    // Headers necesários para a request
  },
});


export default axiosInstance;